import React from 'react'
import './Footer.css'
import Logo from '../../assets/logo.png'
import {LocationMarkerIcon,
        } from '@heroicons/react/outline'

const Footer = () => {
  return (
   <div className="cFooterWrapper">
    <hr />
    <div className="cfooter">
        <div className="logo">
            <img src={Logo} alt="" />
            <span>MyOwnShop</span>
        </div>
        <div className="block">
            <div className="detail">
                <span>Contact Us</span>
                <span className='pngLine'>
                    <LocationMarkerIcon className="icon"/> 
                </span>
            </div>
        </div>
    </div>
   </div>
  )
}

export default Footer
